import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import { isExternalLink } from 'utils/fn';

import { childrenPropTypes, classNamePropTypes } from 'proptypes';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const Link = ({ href, children, className, download, target, ...restProps }) => (isExternalLink(href) || download ? (
  <a
    download={download}
    href={href}
    target={target}
    className={cx('component', className)}
    {...restProps}
  >
    {children}
  </a>
) : (
  <RouterLink to={href} className={cx('component', className)} {...restProps}>
    {children}
  </RouterLink>
));

Link.propTypes = {
  href: PropTypes.string,
  target: PropTypes.string,
  download: PropTypes.bool,
  className: classNamePropTypes,
  children: childrenPropTypes,
};
