import { ContentContext } from 'context';
import { withContext } from 'enhancers/withContext';
import { getProps } from 'enhancers/helpers';

export const withContent = (keys) => (
  (WrappedComponent) => {
    const ConnectedConsumer = (props) => {
      const texts = keys ? getProps({ keys, props: props.contextData }) : props.contextData;

      return <WrappedComponent {...props} {...texts} />;
    };

    /**
     * Override Connector's name to withContext(%Component%)
     */
    if (process.env.NODE_ENV !== 'production') {
      const WrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
      ConnectedConsumer.displayName = `withContent(${WrappedComponentName})`;
    }

    return withContext(ContentContext)(ConnectedConsumer);
  }
);
