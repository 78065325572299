import { useOnMount } from './useOnMount';

export const useFocusOnMount = ({ ref }) => {
  if (!ref || !ref.current) return;

  useOnMount(() => {
    setTimeout(() => {
      ref.current.focus();
    }, 150);
  });
};
