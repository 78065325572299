import PropTypes from 'prop-types';

const PropTypesNewRef = PropTypes.shape({ current: PropTypes.any });

export const childrenPropTypes = PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.func]);
export const classNamePropTypes = PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.func]);
export const componentPropTypes = PropTypes.oneOfType([PropTypes.func]);
export const newRefPropTypes = PropTypesNewRef;
export const refPropTypes = PropTypes.oneOfType([
  PropTypes.func,
  PropTypesNewRef,
]);
