import { useEffect, useState } from 'react';

let timeoutFn;

export const useProgressBar = ({ timeout, onEnd, onStart }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (progress < timeout) {
      timeoutFn = setTimeout(() => {
        setProgress((prev) => prev + 1);
      }, 1000);
    }

    if (progress === timeout) {
      onEnd?.();
    }
  }, [progress]);

  useEffect(() => {
    onStart?.();

    if (progress === timeout) {
      return () => clearTimeout(timeoutFn);
    }
  }, []);

  return progress;
};
