import classNames from 'classnames/bind';

import Logo from 'media/logo.png';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const Header = () => (
  <img
    className={cx('component')}
    alt="Логотип beeline"
    src={Logo}
    loading="lazy"
    width="114"
    height="44"
  />
);
