import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { childrenPropTypes, classNamePropTypes } from 'proptypes';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const Text = ({
  children, isItalic, isPrimary, isSecondary, isBold, isCentered,
  isSmall, isLarge, isInline, isInverse, isError, className,
}) => {
  const Tag = isInline ? 'span' : 'div';

  if (isItalic) throw new Error('Italic font-style is not supported');

  return (
    <Tag
      className={cx('component', className, {
        primary: isPrimary,
        secondary: isSecondary,
        error: isError,
        large: isLarge,
        small: isSmall,
        italic: isItalic,
        inverse: isInverse,
        bold: isBold,
        centered: isCentered,
      })}
      {...(typeof children !== 'string' ? { children } : {
        dangerouslySetInnerHTML: { __html: children },
      })}
    />
  );
};

Text.propTypes = {
  isItalic: PropTypes.bool,
  isPrimary: PropTypes.bool,
  isSecondary: PropTypes.bool,
  isError: PropTypes.bool,
  isSmall: PropTypes.bool,
  isLarge: PropTypes.bool,
  isInline: PropTypes.bool,
  isInverse: PropTypes.bool,
  isBold: PropTypes.bool,
  isCentered: PropTypes.bool,
  className: classNamePropTypes,
  children: childrenPropTypes,
};
