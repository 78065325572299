import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { ERROR_STATUS } from 'constants';

import compose from 'utils/compose';
import { templateParser } from 'utils/formatting';

import { withBreakpoints, withContent } from 'enhancers';

import { useDataContext } from 'hooks';

import { Button, Heading, Text } from 'ui';

const contentKeys = ['error'];

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const COMMON = 'common';

const ErrorPageComponent = ({ error, isMobile }) => {
  const { status } = useParams();
  const { expirationDate } = useDataContext();

  return (
    <div className={cx('component')}>
      <img
        src={error[status || COMMON]?.image}
        className={cx('image')}
        loading="lazy"
        width="120"
        height="120"
        alt="Ошибка"
      />

      <Heading className="marb-xs" isCentered={!isMobile}>
        {error[status || COMMON]?.title}
      </Heading>

      <Text className={cx('description', 'marb-m')} isCentered={!isMobile}>
        {status === ERROR_STATUS.EXPIRED
          ? templateParser(error[status]?.description, { date: expirationDate })
          : error[status || COMMON]?.description}
      </Text>

      {status === ERROR_STATUS.EXPIRED ? null : (
        <Button className={cx('button')} href={error[status || COMMON]?.button?.link}>
          {error[status || COMMON]?.button?.text}
        </Button>
      )}
    </div>
  );
};

ErrorPageComponent.propTypes = {
  error: PropTypes.object,
  isMobile: PropTypes.string,
};

export const ErrorPage = compose(
  withBreakpoints(),
  withContent(contentKeys),
)(ErrorPageComponent);
