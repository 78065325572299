import { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { getCodeParamsRequest } from 'api/requests';

import { CODE_ID } from 'constants';

import { templateParser } from 'utils/formatting';

import { useOnMount, useSearchParamValue } from 'hooks';

import { withContent, withBreakpoints } from 'enhancers';

import { Heading, Text, Button, Preloader } from 'ui';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const contentKeys = ['codeAvailable', 'valid', 'button'];

const QRCodePageComponent = ({ codeAvailable, valid, button, isMobile }) => {
  const codeId = useSearchParamValue(CODE_ID);
  const [codeParams, setCodeParams] = useState({});

  useOnMount(() => {
    if (codeId) {
      getCodeParamsRequest(codeId).then(setCodeParams);
    }
  });

  return (
    <div className={cx('component')}>
      <Heading className={cx(`marb-${isMobile ? 'xs' : 's'}`)} isCentered={!isMobile}>
        {codeAvailable}
      </Heading>

      <div className={cx('code-wrapper')}>
        {codeParams.validTo && (
          <Text className={cx(`marb-${isMobile ? 'xs' : 'se'}`)} isCentered={!isMobile} isSecondary>
            {templateParser(valid, { date: codeParams.validTo })}
          </Text>
        )}

        {codeParams.image ? (
          <img
            src={codeParams.image}
            className={cx('image')}
            loading="lazy"
            width="233"
            height="233"
            alt="QR-код"
          />
        ) : <Preloader className={cx('preloader')} /> }
      </div>

      <Button className={cx('mart-se', 'button')} href={codeParams.image} download="QR.png">
        {button.download}
      </Button>
    </div>
  );
};

QRCodePageComponent.propTypes = {
  codeAvailable: PropTypes.string,
  valid: PropTypes.string,
  button: PropTypes.object,
  isMobile: PropTypes.bool,
};

export const QRCodePage = withBreakpoints()(withContent(contentKeys)(QRCodePageComponent));
