import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { templateParser } from 'utils/formatting';

import { useProgressBar } from './useProgressBar';

import { Text } from '../Text';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const ProgressBar = ({ timeout, onEnd, onStart, timerText, isMobile }) => {
  const progress = useProgressBar({ timeout, onEnd, onStart });

  return (
    <>
      <div className={cx('component', 'marb-xs')}>
        <div className={cx('progress')} style={{ width: `${progress / timeout * 100}%` }} />
      </div>

      {timerText && (
        <Text isSecondary isSmall isCentered={!isMobile}>
          {templateParser(timerText, { seconds: timeout - progress })}
        </Text>
      )}
    </>
  )
};

ProgressBar.propTypes = {
  timeout: PropTypes.number,
  onStart: PropTypes.func,
  onEnd: PropTypes.func,
  timerText: PropTypes.string,
  isMobile: PropTypes.bool,
};

