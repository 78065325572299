export const withContext = (Context, mapContextToProps) => (
  (WrappedComponent) => {
    const ConnectedConsumer = (props) => (
      <Context.Consumer>
        {(contextData) => (
          <WrappedComponent
            {...props}
            contextData={mapContextToProps ? mapContextToProps(contextData, props) : contextData}
          />
        )}
      </Context.Consumer>
    );

    /**
     * Override Connector's name to withContext(%Component%)
     */
    if (process.env.NODE_ENV !== 'production') {
      const WrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
      ConnectedConsumer.displayName = `withContext(${WrappedComponentName})`;
    }
    return ConnectedConsumer;
  }
);
