import { isIE } from 'utils/detectIE';

export const nbsp = '\u00A0';

/**
 * Takes a template like "Age: {{age}}" and replaces {{age}} on values
 * from the object `vars` with the appropriate fields
 * e.g.
 * templateParser('Age: {{age}}', { age: 21 }) => 'Age: 21'
 * @param {string} string
 * @param {object} vars
 */
export const templateParser = (string, vars) => {
  if (!string) return '';
  if (!vars) return string;
  return string.replace(/{{(\w+)}}/ig, (full, match) => vars[match]);
};

/**
 * splits price value into three-digit groups
 * */

export const formatPrice = (number, precision) => (
  typeof number === 'number' ? (
    number.toFixed(precision)
      .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1 ')
      .replace('.', ',')
  ) : ''
);

const phonePattern = '+7 ___ ___ __ __';

export function formatPhone(phone) {
  let ind = 0;
  // eslint-disable-next-line no-plusplus
  return phonePattern.replace(/_/g, () => phone[ind++]);
}

export function formatDate(data) {
  if (!data) return null;

  const d = new Date(data);
  const time = d.toLocaleTimeString().substring(0, isIE() ? 8 : 5);
  const date = d.toLocaleString('ru-RU').substring(0, 10);

  return { time, date };
}

export function formatLoadingDate(data, preposition) {
  if (!data) return null;

  const { time, date } = formatDate(data);

  return date + (preposition ? ' в ' : ' ') + time;
}

export const formatFileName = (string) => {
  if (!string) return;

  const formatIndex = string.lastIndexOf('.');

  return {
    name: string.substring(0, formatIndex),
    format: string.substring(formatIndex + 1, string.length),
  };
};

export const formatToBase64 = (string) => {
  if (!string) return;

  return `data:image/png;base64,${string}`;
};

export const trimSpaces = (string) => {
  if (!string) return;

  return string.replaceAll(' ', '');
};
