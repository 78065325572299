import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { unmapBillingData } from 'api/helpers';
import { personalInfoRequest } from 'api/requests';

import { DEFAULT_ERROR, FORM_EDIT_RESULT, ROUTES } from 'constants';

import { validateForm } from './helpers';

export const useForm = ({ onChange, validationMessages }) => {
  const history = useHistory();
  const [fieldsUpdated, setFieldsUpdated] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (values) => {
    if (error) {
      setError(null);
    }

    onChange(values);
  };

  const handleSubmit = useCallback((values) => {
    if (error) {
      setError(null);
    }

    const mappedValues = unmapBillingData(values);

    setLoading(true);

    return personalInfoRequest(mappedValues)
      .then(() => {
        history.replace(`${ROUTES.result}/${fieldsUpdated ? FORM_EDIT_RESULT.updated : FORM_EDIT_RESULT.confirmed }`);
      })
      .catch(() => {
        setError(DEFAULT_ERROR);
        setLoading(false);
      });
  }, [fieldsUpdated, error]);

  const handleValidate = () => validateForm(validationMessages);

  return {
    onValidate: handleValidate,
    onSubmit: handleSubmit,
    onUpdate: setFieldsUpdated,
    handleChange,
    isLoading,
    error,
  };
};
