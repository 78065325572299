import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { useOnMount, useDataContext } from 'hooks';
import compose from 'utils/compose';
import { withContent, withBreakpoints } from 'enhancers';

import { Heading } from 'ui';
import { PassportForm } from 'components';

const contentKeys = ['confirmPassport', 'fillPassport'];

const FormEditPageComponent = ({ confirmPassport, fillPassport, isMobile }) => {
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [formValues, setFormValues] = useState({});
  const { billingData } = useDataContext();
  const headingText = useMemo(() => isConfirmed ? confirmPassport : fillPassport, [isConfirmed]);

  useOnMount(() => {
    setIsConfirmed(billingData.confirmed);

    if (billingData.confirmed) {
      setFormValues(billingData.formValues);
    }
  });

  return (
    <>
      <Heading className="marb-m" isCentered={!isMobile}>
        {headingText}
      </Heading>

      <PassportForm isConfirmed={isConfirmed} values={formValues} onChange={setFormValues} />
    </>
  );
};

FormEditPageComponent.propTypes = {
  confirmPassport: PropTypes.string,
  fillPassport: PropTypes.string,
  isMobile: PropTypes.bool,
};

export const FormEditPage = compose(
  withBreakpoints(),
  withContent(contentKeys),
)(FormEditPageComponent);
