import { composeCheckedValidator, composeLengthValidator, composeCyrillicValidator, isEmptyFn } from 'utils/field-validators';

export const validateForm = (messages) => ({
  surname: [
    ...isEmptyFn(messages),
    ...composeCyrillicValidator(messages.invalid),
  ],
  name: [
    ...isEmptyFn(messages),
    ...composeCyrillicValidator(messages.invalid),
  ],
  patronymic: [
    ...isEmptyFn(messages),
    ...composeCyrillicValidator(messages.invalid),
  ],
  passport: [
    ...isEmptyFn(messages),
    ...composeLengthValidator(messages.length,{ min: 10, max: 10 }),
  ],
  checkbox: composeCheckedValidator(messages.checkbox),
});

export const checkUpdatedFields = (modifiedFields) => {
  const fieldsWithoutCheckbox = Object.keys(modifiedFields).filter((key) => key !== 'checkbox');

  return !!fieldsWithoutCheckbox.find((key) => modifiedFields[key]);
};
