import classNames from 'classnames/bind';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const Digits = ({ isLoading, children }) => (
  <div className={cx('component', { isLoading })}>
    {children}
  </div>
);
