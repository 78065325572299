import { useHistory } from 'react-router-dom';
import { useRequest } from 'ahooks';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { pushPollingRequest } from 'api/requests';

import { CODE_ID, ERROR_STATUS, ROUTES } from 'constants';

import compose from 'utils/compose';

import { useDataContext } from 'hooks';

import { withContent, withBreakpoints } from 'enhancers';

import { Heading, ProgressBar, Text } from 'ui';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const contentKeys = ['loadingTexts'];
const timeout = 70;

const LoadingPageComponent = ({ loadingTexts, isMobile }) => {
  const history = useHistory();
  const { setBillingData } = useDataContext();

  const { run: startPolling, cancel: cancelPolling } = useRequest(pushPollingRequest, {
    manual: true,
    pollingInterval: 1000,
    onSuccess: (data) => {
      if (data.ready) {
        if (data.confirmed) {
          setBillingData(data);
          cancelPolling();
        }

        if (data.code_id) {
          cancelPolling();
          history.replace(`${ROUTES.code}?${CODE_ID}=${data.code_id}`);
          return;
        }

        if (data.push_denied) {
          cancelPolling();
          history.replace(`${ROUTES.error}/${ERROR_STATUS.DENIED}`);
          return;
        }

        history.replace(ROUTES.form);
      }
    },
    onError: () => {
      history.replace(`${ROUTES.error}/${ERROR_STATUS.COMMON}`);
    }
  });

  const handleProgressEnd = () => {
    cancelPolling();
    history.replace(`${ROUTES.error}/${ERROR_STATUS.DENIED}`)
  };

  return (
    <div className={cx('component')}>
      <img
        src={loadingTexts?.image}
        className={cx('image', 'marb-s')}
        loading="lazy"
        width="120"
        height="120"
        alt=""
      />

      <Heading className="marb-s" isCentered={!isMobile}>
        {loadingTexts?.title}
      </Heading>

      <Text className="marb-se" isCentered={!isMobile} isSecondary>
        {loadingTexts?.description}
      </Text>

      <ProgressBar
        timeout={timeout}
        onEnd={handleProgressEnd}
        onStart={startPolling}
        timerText={loadingTexts?.timer}
        isMobile={isMobile}
      />
    </div>
  );
};

LoadingPageComponent.propTypes = {
  loadingTexts: PropTypes.object,
  isMobile: PropTypes.bool,
};

export const LoadingPage = compose(
  withBreakpoints(),
  withContent(contentKeys),
)(LoadingPageComponent);
