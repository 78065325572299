import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import compose from 'utils/compose';

import { withContent, withBreakpoints } from 'enhancers';

import { Heading, Text } from 'ui';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const contentKeys = ['success'];

const ResultPageComponent = ({ success, isMobile }) => {
  const { status } = useParams();

  return (
    <div className={cx('component')}>
      <img
        src="https://static.beeline.ru/upload/MobileID/images/aggregator/done.png"
        className={cx('image')}
        loading="lazy"
        width="120"
        height="120"
        alt="Успех"
      />

      <Heading className="marb-xs" isCentered={!isMobile}>
        {success[status]?.title}
      </Heading>

      <Text isCentered={!isMobile} isSecondary>
        {success[status]?.description}
      </Text>
    </div>
  );
};

ResultPageComponent.propTypes = {
  success: PropTypes.object,
  isMobile: PropTypes.bool,
};

export const ResultPage = compose(
  withBreakpoints(),
  withContent(contentKeys),
)(ResultPageComponent);
