import { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { childrenPropTypes } from 'proptypes';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const Checkbox = ({
  id,
  name,
  value,
  children,
  disabled,
  onBlur,
  onFocus,
  onChange,
}) => {
  const handleBlur = useCallback((e) => {
    onBlur?.(value, e);
  }, [value, onBlur]);

  const handleFocus = useCallback((e) => {
    onFocus?.(value, e);
  }, [value, onFocus]);

  const handleChange = useCallback((e) => {
    onChange?.(e.target.checked, e);
  }, [onChange]);

  return (
    <div className={cx('component')}>
      <input
        type="checkbox"
        id={id}
        name={name}
        checked={value}
        disabled={disabled}
        className={cx('checkbox')}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onChange={handleChange}
      />

      {children && (
        <label htmlFor={id}>
          {children}
        </label>
      )}
    </div>
  );
};

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.bool,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  children: childrenPropTypes,
};
