import { Switch, Route, Redirect } from 'react-router-dom';
import classNames from 'classnames/bind';

import { ERROR_STATUS, ROUTES } from 'constants';

import compose from 'utils/compose';
import { Header, Footer } from 'shared';

import {
  withAdaptivity,
  withReactRouter,
  withFetchedContent,
  withBillingData,
} from '../enhancers';

import { routes } from './routes';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const App = () => (
  <div className={cx('container')}>
    <div className={cx('component')}>
      <Header />

      <div className={cx('content')}>
        <Switch>
          {routes.map((route) => (
            <Route key={route.path} {...route} />
          ))}

          <Redirect from="/*" to={`${ROUTES.error}/${ERROR_STATUS.COMMON}`} />
        </Switch>
      </div>
    </div>

    <Footer />
  </div>
);

export default compose(
  withReactRouter(),
  withAdaptivity(),
  withFetchedContent(),
  withBillingData(),
)(App);
