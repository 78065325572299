import { Checkbox } from '../Checkbox';
import { PassportNumberInput, TextInput } from '../TextInput';

import withFinalField from './enhancers/withFinalField';
import { withError, withLabel } from '../TextInput/enhancers';
import { DigitInput } from 'components/CheckDigitsForm/components/DigitInput';

export const FFTextInput = withFinalField(TextInput);
export const FFCheckbox = withFinalField({ type: 'checkbox' })(withError(withLabel(Checkbox)));
export const FFDigitInput = withFinalField(DigitInput);
export const FFPassportNumberInput = withFinalField(withError(PassportNumberInput));

export { FinalForm } from './FinalForm';
