import { VERIFY_ID } from 'constants';

/**
 * Функция возращает значение куки по ключу
 * @param {string} name - ключ для куки
 * @returns {string|undefined|null}
 */
export const getCookie = (name) => {
  if (typeof document === 'undefined') return null;

  const matches = document.cookie.match(new RegExp(
    // eslint-disable-next-line no-useless-escape
    `(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`,
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

/**
 * Функция ставит куку
 * @param {string} name - Имя куки
 * @param {string|number} [value=''] - Значение куки
 * @param {string} [domain] - Домен, на котором доступна кука
 * @param {string|number} [ttl] - Дата истечения срока действия куки
 * @param {string} [path='/'] - path url
 * @param {string|number} [maxAge] - Время жизни куки в секундах
 */
export const setCookie = ({ name, value = '', domain, expires, path = '/', maxAge }) => {
  // eslint-disable-next-line max-len
  document.cookie = `${name}=${value}${domain ? `;domain=${domain}` : ''}${path ? `;path=${path}` : ''}${expires ? `;expires=${new Date(expires * 1000).toUTCString()}` : ''}${maxAge ? `;max-age=${maxAge}` : ''}`;
};

/**
 * Функция удаляет куку по ключу
 * @param {string} name - ключ для куки
 */
export const deleteCookie = (name) => {
  setCookie({
    name,
    maxAge: -1,
  });
};

/**
 * Функция возращает объект с куками по из заголовков запроса
 * @param {string} headers - заголовки запроса
 * @returns {object|undefined}
 */

export const getCookiesFromRequest = (headers) => {
  if (headers.cookie) {
    return headers.cookie.replaceAll('; ', ';').split(';').reduce((cookiesData, item) => {
      const [key, value] = item.split('=');
      cookiesData[key] = value;
      return cookiesData;
    }, {});
  }
};

export const setVerifyId = (value) => {
  setCookie({ name: VERIFY_ID, value, expires: 2147483647 });
};

export const getVerifyId = () => getCookie(VERIFY_ID);
