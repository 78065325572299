import { axiosBaseRequest } from 'api/helpers';
import { verifyDataUrl } from 'api/endpoints';

export const verifyDataRequest = ({ doc_num, verify_id }) => axiosBaseRequest({
  url: verifyDataUrl,
  params: {
    doc_num,
    verify_id,
  },
});
