import { NumberMaskedInput } from 'ui/TextInput/components/NumberMaskedInput';

export const PassportNumberInput = (({ className, ...restProps }) => (
  <NumberMaskedInput
    className={className}
    pattern="[0-9]*"
    maxLength={11}
    mask="____ ______"
    {...restProps}
  />
));
