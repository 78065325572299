import React, { Component } from 'react';
import { getWindowWidth, simulateResize } from './helpers';
import { BREAKPOINT_1004, breakpoints } from 'constants/index';
import { BreakpointsContext } from 'context';
import { isDesktopVersion, isMobileVersion, isTabletVersion } from './helpers';

/**
 * Listens resize to determine resolution, and pushes current breakpoint value into context
 */
export default function withAdaptivity() {
  return (WrappedComponent) => {
    class ResizeListener extends Component {
      state = { isReady: false, breakpoint: BREAKPOINT_1004 };
      /**
       * initially loads Desktop to properly handle React.hydrate
       * afterwards
       */
      componentDidMount() {
        window.addEventListener('resize', this.updateBreakpoint);
        this.updateBreakpoint();
      }

      /**
       * this function fires each time after setBreakpoint updated the react tree
       * we simulate resize at this point in order to fix possible issues caused by late react tree re-render
       */
      componentDidUpdate() {
        simulateResize();
      }

      updateBreakpoint = () => {
        const windowWidth = getWindowWidth();

        /** find closest breakpoint that we are surpassed in width */
        const newBreakpoint = breakpoints.find(bp => bp <= windowWidth);

        /** only call dispatcher if value changed */
        if (this.state.breakpoint !== newBreakpoint) {
          this.setState({ breakpoint: newBreakpoint })
        }

        if (!this.state.isReady) this.setState({ isReady: true });
      };

      render() {
        return this.state.isReady ? (
          <BreakpointsContext.Provider
            value={{
              isMobile: isMobileVersion(this.state.breakpoint),
              isTablet: isTabletVersion(this.state.breakpoint),
              isDesktop: isDesktopVersion(this.state.breakpoint),
            }}
          >
            <WrappedComponent />
          </BreakpointsContext.Provider>
        ) : null;
      }
    }

    return ResizeListener;
  };
}

