import { useMemo } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import { childrenPropTypes } from 'proptypes';

import { Text } from '../Text';
import { Link } from '../Link';
import { PreloaderPlain } from '../PreloaderPlain';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const View = ({
  id,
  type,
  href,
  download,
  onClick,
  isInline,
  disabled,
  children,
  isLoading,
  ariaLabel,
  className,
}) => {
  const Component = useMemo(() => href ? Link : 'button', [href]);

  return (
    <Component
      id={id}
      type={type}
      href={href}
      download={download}
      onClick={onClick}
      aria-label={ariaLabel}
      disabled={disabled || isLoading}
      className={cx('component', className)}
      style={{ display: isInline ? 'inline-block' : 'block' }}
    >
      <Text
        isInline
        isCentered
        isDisabled={disabled || isLoading}
        isBold500
        className={cx('text')}
      >
        {
          isLoading
            ? <PreloaderPlain className={cx('preloader')} size={20} />
            : children
        }
      </Text>
    </Component>
  );
};

View.propTypes = {
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onClick: PropTypes.func,
  isInline: PropTypes.bool,
  disabled: PropTypes.bool,
  download: PropTypes.bool,
  isLoading: PropTypes.bool,
  children: childrenPropTypes,
}
