import classnames from 'classnames/bind';

import styles from './styles.pcss';
const cx = classnames.bind(styles);

export const PreloaderPlain = ({ className, size = 16 }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={cx('preloader', className)}>
    <path d="M8 15a7 7 0 1 1 7-7" stroke="#ffe02c" strokeWidth="2" strokeLinecap="round" />
  </svg>
);
