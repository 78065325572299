import { composeLengthValidator, composeRequiredValidator } from 'utils/field-validators';

export const fields = [0, 1, 2, 3];

export const firstField = fields[0];
export const lastField = fields[fields.length - 1];

export const backspaceKeyCode = 8;

export const validateInput = (value) => /^\d$/.test(value);

export const validateForm = (messages) => ({ digits: [
  ...composeRequiredValidator(messages.required),
  ...composeLengthValidator(messages.length,{ min: 4, max: 4 }),
]});

