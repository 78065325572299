import { useContext } from 'react';
import { DataContext } from 'context';

export { useEventListener } from './useEventListener';
export { useLockScroll } from './useLockScroll';
export { useMemoOnce } from './useMemoOnce';
export { useWillMount } from './useWillMount';
export { useOnMount } from './useOnMount';
export { useFocusOnMount } from './useFocusOnMount';
export { useSearchParamValue } from './useSearchParamValue';

export const useDataContext = () => useContext(DataContext);
