// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RIcZl4 {\n  position: relative;\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: -ms-flexbox;\n  display: flex;\n}\n\n  .RIcZl4.hkHwzL {\n    pointer-events: none;\n  }\n", "",{"version":3,"sources":["webpack://./src/components/CheckDigitsForm/components/Digits/styles.pcss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,oBAAa;EAAb,qBAAa;EAAb,oBAAa;EAAb,aAAa;AAKf;;EAHE;IACE,oBAAoB;EACtB","sourcesContent":[".component {\n  position: relative;\n  display: flex;\n\n  &.isLoading {\n    pointer-events: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"component": "RIcZl4",
	"isLoading": "hkHwzL"
};
export default ___CSS_LOADER_EXPORT___;
