import { LoginPage } from './Login';
import { FormEditPage } from './FormEdit';
import { ResultPage } from './Result';
import { QRCodePage } from './QRCode';
import { ErrorPage } from './Error';
import { LoadingPage } from './Loading';

import { ROUTES } from 'constants';

export const routes = [
  {
    path: ROUTES.login,
    component: LoginPage,
    exact: true,
  },
  {
    path: ROUTES.form,
    component: FormEditPage,
    exact: true,
  },
  {
    path: `${ROUTES.result}/:status`,
    component: ResultPage,
    exact: true,
  },
  {
    path: `${ROUTES.code}`,
    component: QRCodePage,
    exact: true,
  },
  {
    path: `${ROUTES.error}/:status`,
    component: ErrorPage,
    exact: true,
  },
  {
    path: ROUTES.await,
    component: LoadingPage,
    exact: true,
  }
];
