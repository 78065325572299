import classNames from 'classnames/bind';

import { withContent } from 'enhancers';

import { fields } from './helpers';

import { useDigitsInput } from './useDigitsInput';

import { Button, FinalForm, Text } from 'ui';
import { Digit, Digits, Input } from './components';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const contentKeys = ['button', 'validationMessages'];

const CheckDigitsFormComponent = ({ button, validationMessages }) => {
  const {
    onChange,
    onKeyDown,
    onSubmit,
    onValidate,
    onClick,
    activeField,
    error,
    isLoading,
    inputRef,
  } = useDigitsInput({ validationMessages });

  return (
    <FinalForm
      className={cx('form')}
      name="checkDigits"
      isValidateOnSubmit
      onSubmit={onSubmit}
      getValidators={onValidate}
    >
      {({ values, submitErrors }) => (
        <>
          <Digits isLoading={isLoading}>
            {fields.map((field) => (
              <Digit key={field} className={cx('field')} isActive={activeField === field}>
                {values?.digits?.[field]}
              </Digit>
            ))}

            <Input
              name="digits"
              className={cx('input')}
              onChangeProxy={onChange}
              onKeyDownProxy={onKeyDown}
              onClick={onClick}
              autoComplete="off"
              focusOnMount
              domNodeRef={inputRef}
            />
          </Digits>

          {(submitErrors?.digits || error) && (
            <Text isError className="mart-xs">
              {submitErrors?.digits || error}
            </Text>
          )}

          <Button
            className={cx('button')}
            type="submit"
            isLoading={isLoading}
          >
            {button.ready}
          </Button>
        </>
      )}
    </FinalForm>
  )
};

export const CheckDigitsForm = withContent(contentKeys)(CheckDigitsFormComponent);
