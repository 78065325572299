import axios, { CancelToken } from 'axios';

import { PUSH_DENIED_ERROR } from 'constants';

import { formatToBase64, trimSpaces } from 'utils/formatting';
import { getVerifyId } from 'utils/cookie';
import { isEmptyObject } from 'utils/fn';


/**
 * A base request with common params predefined
 * fully customizable, baseRequest is used to prevent boilerplate code generation
 * @returns {AxiosPromise}
 */
export const axiosBaseRequest = ({
  method = 'GET',
  url,
  responseType = 'json',
  withCredentials = true,
  timeout = 60000,
  config,
  ...options
}) => {
  const source = CancelToken.source();
  if (timeout) {
    setTimeout(() => {
      source.cancel();
    }, timeout);
  }

  return axios({
    method,
    url,
    responseType,
    withCredentials,
    config,
    ...options,
    cancelToken: source.token,
  });
};

export const mapBillingData = ({ data }) => {
  if (!data) return {};

  return ({
    confirmed: data?.equals,
    formValues: {
      surname: data?.person_info?.family_name,
      name: data?.person_info?.given_name,
      patronymic: data?.person_info?.middle_name,
      passport: trimSpaces(data?.person_info?.national_identifier),
      checkbox: false,
    },
  });
};

export const unmapBillingData = (data) => {
  if (!data) return {};

  return ({
    family_name: data.surname,
    given_name: data.name,
    middle_name: data.patronymic,
    national_identifier: data.passport,
    verify_id: getVerifyId(),
  });
};

export const mapCodeParams = ({ data }) => {
  if (isEmptyObject(data)) return {};

  return ({
    validTo: data.date || '',
    image: formatToBase64(data.qrCode),
  });
};

export const mapPollingData = ({ data }) => {
  if (isEmptyObject(data)) return {};

  return {
    ...mapBillingData(data),
    ready: data.ready,
    code_id: data.data?.code_id,
    error_code: data.data?.error_code,
    push_denied: data.data?.error_description === PUSH_DENIED_ERROR,
  }
}
