import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { withContent } from 'enhancers';

import { templateParser } from 'utils/formatting';

import { Text } from 'ui';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const content = ['footer'];

const FooterComponent = ({ footer }) => (
  <div className={cx('component')}>
    <Text className={cx('text')} isSmall>
      {templateParser(footer, { year: new Date().getFullYear() })}
    </Text>
  </div>
);

FooterComponent.propTypes = {
  footer: PropTypes.string,
};

export const Footer = withContent(content)(FooterComponent);
