import { NumberMaskedInput } from 'ui/TextInput/components/NumberMaskedInput';

export const DigitInput = ({ className, ...restProps }) => (
  <NumberMaskedInput
    className={className}
    pattern="[0-9]*"
    maxLength={4}
    mask="____"
    {...restProps}
  />
);
