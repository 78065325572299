import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import { childrenPropTypes, classNamePropTypes } from 'proptypes';

import { Text } from 'ui';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const DigitContainer = ({ className, isActive, children }) => (
  <div className={cx('component', className, { isActive }, !children && 'isEmpty')}>
    <Text>
      {children}
    </Text>
  </div>
);

DigitContainer.propTypes = {
  isActive: PropTypes.bool,
  classNames: classNamePropTypes,
  children: childrenPropTypes,
};
