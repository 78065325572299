import { useState } from 'react';

import { useSearchParamValue } from 'hooks';

import { VERIFY_ID } from 'constants';

import { DataContext } from 'context';

import { setVerifyId } from 'utils/cookie';

export const withBillingData = () => (
  (WrappedComponent) => {
    const ConnectedConsumer = (props) => {
      const [billingData, setBillingData] = useState({});
      const [expirationDate, setExpirationDate] = useState('');
      const verify_id = useSearchParamValue(VERIFY_ID);

      if (verify_id) {
        setVerifyId(verify_id);
      }

      return (
        <DataContext.Provider value={{ billingData, setBillingData, expirationDate, setExpirationDate }}>
          <WrappedComponent {...props} />
        </DataContext.Provider>
      );
    };

    /**
     * Override Connector's name to withContext(%Component%)
     */
    if (process.env.NODE_ENV !== 'production') {
      const WrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
      ConnectedConsumer.displayName = `withBillingData(${WrappedComponentName})`;
    }

    return ConnectedConsumer;
  }
);
