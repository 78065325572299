import { useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { verifyDataRequest } from 'api/requests';

import { getVerifyId } from 'utils/cookie';

import { DEFAULT_ERROR, ROUTES } from 'constants';

import { useDataContext } from 'hooks';

import {
  backspaceKeyCode,
  firstField,
  lastField,
  validateForm,
  validateInput,
} from './helpers';

export const useDigitsInput = ({ validationMessages }) => {
  const history = useHistory();
  const inputRef = useRef(null);
  const { setExpirationDate } = useDataContext();
  const [activeField, setActiveField] = useState(firstField);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const resetError = () => setError(null);

  const setNextField = useCallback((event) => {
    if (activeField === lastField) return;

    setActiveField(event.target.selectionStart);
  }, [activeField]);

  const setPrevField = useCallback(() => {
    if (activeField === firstField) return;

    setActiveField((prev) => prev - 1);
  }, [activeField]);

  const handleChange = useCallback((value, event) => {
    if (error) {
      resetError();
    }

    const lastChange = value[activeField];

    if (validateInput(lastChange)) {
      setNextField(event);
    }

    return value;
  }, [activeField, error]);

  const handleKeyDown = ({ keyCode }) => {
    if (keyCode === backspaceKeyCode) {
      setPrevField();
    }
  };

  const handleSubmit = (values) => {
    if (error) {
      resetError();
    }

    setLoading(true);
    inputRef.current?.blur();

    return verifyDataRequest({
      doc_num: values.digits,
      verify_id: getVerifyId(),
    })
      .then(() => history.push(ROUTES.await))
      .catch(({ response }) => {
        if (response?.data?.error_code === 5) {
          setExpirationDate(response?.data?.data?.date);
          history.replace(`${ROUTES.error}/expired`);
          return;
        }
        setLoading(false);
        setError(DEFAULT_ERROR);
      });
  };

  const handleValidate = () => validateForm(validationMessages);

  const handleClick = (e) => {
    e.target.setSelectionRange(e.target.value.length, 4);
  };

  return {
    activeField,
    error,
    isLoading,
    inputRef,
    onChange: handleChange,
    onKeyDown: handleKeyDown,
    onSubmit: handleSubmit,
    onValidate: handleValidate,
    onClick: handleClick,
  };
};
