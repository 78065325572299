import { useMemo } from 'react';
import { FormSpy } from 'react-final-form';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { withContent } from 'enhancers';
import { Button, FinalForm, Text } from 'ui';
import { FFCheckbox, FFTextInput, FFPassportNumberInput } from 'ui/FinalForm';

import { useForm } from './useForm';
import { checkUpdatedFields } from './helpers';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const contentKeys = ['formFields', 'button', 'validationMessages'];

const PassportFormComponent = ({ formFields, button, validationMessages, values, isConfirmed, onChange }) => {
  const buttonText = useMemo(() => (
    isConfirmed ? button.confirm : button.update
  ), [isConfirmed]);

  const {
    onValidate,
    onSubmit,
    onUpdate,
    handleChange,
    isLoading,
    error,
  } = useForm({ onChange, validationMessages });

  return (
    <FinalForm
      className={cx('form')}
      name="passport"
      onSubmit={onSubmit}
      getValidators={onValidate}
      values={values}
      onChange={handleChange}
    >
      {({ valid }) => (
        <>
          <FormSpy
            subscription={{ values: true, modified: true }}
            onChange={({ modified }) => {
              onUpdate(checkUpdatedFields(modified));
            }}
          />

          <div className={cx('container')}>
            <FFTextInput className={cx('field')} name="surname" label={formFields.surname} />
            <FFTextInput className={cx('field')} name="name" label={formFields.name} />
            <FFTextInput className={cx('field')} name="patronymic" label={formFields.patronymic} />
            <FFPassportNumberInput className={cx('field')} name="passport" label={formFields.passport} />
            <FFCheckbox className={cx('field', 'mart-m')} id="checkbox" name="checkbox">
              <Text className="marb-xs">
                {formFields.checkbox}
              </Text>
            </FFCheckbox>
          </div>

          {error && (
            <Text isError className="mart-xs">
              {error}
            </Text>
          )}

          <Button className={cx('button')} type="submit" disabled={!valid} isLoading={isLoading}>
            {buttonText}
          </Button>
        </>
      )}
    </FinalForm>
  );
};

PassportFormComponent.propTypes = {
  formFields: PropTypes.object,
  button: PropTypes.object,
  validationMessages: PropTypes.object,
  values: PropTypes.object,
  isConfirmed: PropTypes.bool,
  onChange: PropTypes.func,
};

export const PassportForm = withContent(contentKeys)(PassportFormComponent);
