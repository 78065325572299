import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { classNamePropTypes } from 'proptypes';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const Preloader = ({ dark, className }) => (
  <div className={cx('component', { dark }, className)} />
);

Preloader.propTypes = {
  dark: PropTypes.bool,
  className: classNamePropTypes,
};
