import PropTypes from 'prop-types';

import { View } from './View';

export const Button = ({ handleClick, ...restProps }) => (
  <View onClick={handleClick} {...restProps} />
);

Button.propTypes = {
  href: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  download: PropTypes.bool,
  handleClick: PropTypes.func,
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
