import { BREAKPOINT_768, BREAKPOINT_1280 } from 'constants';
import { filter } from 'utils/fn';

/**
 * this function is required to fix issues with sidebar,
 * caused by untracked layout changes
 */
export const simulateResize = () => {
  const resizeEvent = window.document.createEvent('UIEvents');
  resizeEvent.initUIEvent('resize', true, false, window, 0);
  window.dispatchEvent(resizeEvent);
};

export const isIE11 = () => !!window.MSInputMethodContext && !!document.documentMode;

export const getWindowWidth = () => (
  window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
);

/**
 * Selectors for breakpoints, top resolution is INCLUDED in interval
 */
export const isScreenBelow768 = breakpoint => breakpoint < BREAKPOINT_768;
export const isScreenBelow1280 = breakpoint => breakpoint < BREAKPOINT_1280;

export const isMobileVersion = breakpoint => isScreenBelow768(breakpoint);
export const isTabletVersion = breakpoint => !isScreenBelow768(breakpoint) && isScreenBelow1280(breakpoint);
export const isDesktopVersion = breakpoint => !isScreenBelow1280(breakpoint);

export const getProps = ({ keys, props }) => filter(props, (_, prop) => keys.includes(prop));
