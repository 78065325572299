import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { classNamePropTypes, childrenPropTypes } from 'proptypes';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const Heading = ({
  isCentered,
  tagName,
  level,
  children,
  className,
}) => {
  const Tag = tagName || `h${level}`;

  return (
    <Tag
      className={cx('component', className, { centered: isCentered })}
      {...(typeof children !== 'string' ? { children } : {
        dangerouslySetInnerHTML: { __html: children },
      })}
    />
  );
};

Heading.defaultProps = {
  level: 1,
};

Heading.propTypes = {
  level: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  tagName: PropTypes.string,
  isCentered: PropTypes.bool,
  className: classNamePropTypes,
  children: childrenPropTypes,
};
