import { useState } from 'react';

import { requestTexts } from 'api/requests';

import { ContentContext } from 'context';

import { useWillMount } from 'hooks';

import { Preloader } from 'ui';

import texts from '../../server/routes/stubs/texts.json';

export const withFetchedContent = () => (
  (WrappedComponent) => {
    // TODO: uncomment when we can get this data from QP
    /* const Component = (props) => {
      const [content, setContent] = useState(null);
      const [isLoading, setLoading] = useState(true);

      useWillMount(() => {
        requestTexts().then(setContent).finally(() => setLoading(false));
      });

      return isLoading ? <Preloader /> : (
        <ContentContext.Provider value={content}>
          <WrappedComponent {...props}/>
        </ContentContext.Provider>
      )
    }; */

    const Component = (props) => (
      <ContentContext.Provider value={texts}>
        <WrappedComponent {...props}/>
      </ContentContext.Provider>
    );

    /**
     * Override Connector's name to withContext(%Component%)
     */
    if (process.env.NODE_ENV !== 'production') {
      const WrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
      Component.displayName = `withFetchedContent(${WrappedComponentName})`;
    }

    return Component;
  }
);
