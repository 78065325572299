export const BREAKPOINT_0 = 0;
export const BREAKPOINT_768 = 768;
export const BREAKPOINT_1004 = 1004;
export const BREAKPOINT_1280 = 1280;

/**
 * Lower value of breakpoint is used to enum interval,
 * last breakpoint - infinity, is used for conveniency of comparison
 */
export const breakpoints = [Number.MAX_VALUE, BREAKPOINT_1280, BREAKPOINT_1004, BREAKPOINT_768, BREAKPOINT_0 ];

export const FORM_EDIT_RESULT = {
  updated: 'updated',
  confirmed: 'confirmed',
};

export const ROUTES = {
  login: '/b2b/login',
  form: '/b2b/edit-form',
  result: '/b2b/success',
  error: '/b2b/error',
  code: '/b2b/code',
  await: '/b2b/await',
};

export const ERROR_STATUS = {
  DENIED: 'denied',
  COMMON: 'common',
  EXPIRED: 'expired',
};

export const PUSH_DENIED_ERROR = "Push hasn't been approved";

export const VERIFY_ID = 'verify_id';

export const CODE_ID = 'code_id';

export const DEFAULT_ERROR = 'Что-то пошло не так.';
