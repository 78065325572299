import { BreakpointsContext } from 'context';
import { withContext } from 'enhancers/withContext';

export const withBreakpoints = () => (
  (WrappedComponent) => {
    const ConnectedConsumer = (props) => <WrappedComponent {...props} {...props.contextData} />;

    /**
     * Override Connector's name to withContext(%Component%)
     */
    if (process.env.NODE_ENV !== 'production') {
      const WrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
      ConnectedConsumer.displayName = `withBreakpoints(${WrappedComponentName})`;
    }

    return withContext(BreakpointsContext)(ConnectedConsumer);
  }
);
