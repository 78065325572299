import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { classNamePropTypes, refPropTypes } from 'proptypes';

import { valueToPixel } from '../enhancers/helpers';
import { useFocusOnMount } from 'hooks';

import styles from '../styles.pcss';
const cx = classNames.bind(styles);


/**
 * Input with visual customization
 */
const InputView = ({
  onChange,
  onFocus,
  onBlur,
  onKeyDown,
  value,
  size,
  status,
  width,
  className,
  domNodeRef,
  focusOnMount,
  autoComplete = 'on',
  ...otherProps
}) => {
  /**
   * we have to override onChange, onFocus and onBlur,
   * normally they fire with event as first argument,
   * we put value as first argument, event as second
   */
  const handleChange = (event) => {
    onChange?.(event.target.value, event);
  };

  const handleFocus = (event) => {
    onFocus?.(value, event);
  };

  const handleBlur = (event) => {
    onBlur?.(value, event);
  };

  const handleKeyDown = (event) => {
    onKeyDown?.({ value: event.target.value, event });
  }

  /**
   * Fields like status and size are passed straight into class, the validation is done in propTypes
   */
  const classes = cx(
    'component',
    size,
    status,
    className,
  );

  if (focusOnMount) {
    useFocusOnMount({ ref: domNodeRef });
  }

  return (
    <input
      autoComplete={autoComplete}
      style={{ maxWidth: valueToPixel(width) }}
      value={value}
      {...otherProps}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      className={classes}
      ref={domNodeRef}
    />
  );
}


InputView.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  status: PropTypes.oneOf(['fail']),
  size: PropTypes.oneOf(['big']),
  /**
   * width of input, HOCs that override DOM container are supposed to apply width to their container
   */
  width: PropTypes.number,
  className: classNamePropTypes,

  /**
   * some notable default input props are listed here
   * all the other props are simply passed down
   */
  placeholder: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,

  /** is not an actual prop */
  domNodeRef: refPropTypes,
};

export default InputView;
