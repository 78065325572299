import PropTypes from 'prop-types';

import compose from 'utils/compose';

import { withContent, withBreakpoints } from 'enhancers';

import { Heading } from 'ui';
import { CheckDigitsForm } from 'components';

const contentKeys = ['billingDataConfirmation'];

const LoginPageComponent = ({ billingDataConfirmation, isMobile }) => (
  <>
    <Heading className="marb-m" isCentered={!isMobile}>
      {billingDataConfirmation}
    </Heading>

    <CheckDigitsForm />
  </>
);

LoginPageComponent.propTypes = {
  billingDataConfirmation: PropTypes.string,
  isMobile: PropTypes.bool,
};

export const LoginPage = compose(
  withBreakpoints(),
  withContent(contentKeys),
)(LoginPageComponent);
